import "./webapps.css";
import marins from "./images/marins.PNG";
import gamecube from "./images/gamecube.png";
import Creation from "./creation";
import { useEffect } from "react";

export default function WebApps(props) {

    useEffect(() => document.title=`Léonard Reibel - ${props.i18n?.projects}`)

    return (
        <div className="inside-wrap">
            <div className="webapps">
                <h1>{props.i18n?.projects}</h1>
                <p>{props.i18n?.projects2}</p>
                <div className="creations">
                    <Creation i18n={props.i18n} name="Les Amis des Marins" image={marins} link="https://seamensclub-fos.fr/en/home/">
                        <p>{props.i18n?.projects3}</p>
                        <p>{props.i18n?.projects4}</p>
                        <p>{props.i18n?.projects5}</p>
                    </Creation>
                    <Creation i18n={props.i18n} name="Animation - GameCube" image={gamecube} link="https://demo.sieepwalker.com/">
                            <p>{props.i18n?.projects6}</p>
                            <p>{props.i18n?.projects7}</p>
                    </Creation>
                </div>
            </div>
        </div>
    );
}
