import "./sidebar.css";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { SplitText } from "gsap/SplitText";

export default function Sidebar(props) {

    gsap.registerPlugin(SplitText);
    const [splitTexts, setSplitTexts] = useState({
        accueil: null,
        pres: null,
        appweb: null,
        contact: null
    })
    const sidebar = useRef(null);

    useEffect(() => {
        switch (props.showSidebar) {
            case "show":
                gsap.to(sidebar.current, { xPercent: 0, visibility: "inherit" });
                break;
            case "hide":
                gsap.to(sidebar.current, { xPercent: 100, visibility: "inherit" });
                break;
            case "instant":
                gsap.set(sidebar.current, { xPercent: 0, visibility: "inherit" });
                break;
            case "init":
                gsap.set(sidebar.current, { xPercent: 100, visibility: "inherit" });
                break;
            default:
        }
    }, [props.showSidebar])

    return (
        <div className="sidebar" ref={sidebar}>
            <p className="leo">Léonard Reibel</p>
            <ul >
                <li><Link to="/" id="accueil" tabIndex={(props.showSidebar === "init" || props.showSidebar === "hide") ? -1 : 0}
                    onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}
                    onFocus={handleHover} onBlur={handleMouseLeave}>{props.i18n?.home}</Link></li>
                <li><Link to="/presentation" id="pres" tabIndex={(props.showSidebar === "init" || props.showSidebar === "hide") ? -1 : 0}
                    onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}
                    onFocus={handleHover} onBlur={handleMouseLeave}>{props.i18n?.about}</Link></li>
                <li><Link to="/appweb" id="appweb" tabIndex={(props.showSidebar === "init" || props.showSidebar === "hide") ? -1 : 0}
                    onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}
                    onFocus={handleHover} onBlur={handleMouseLeave}>{props.i18n?.projects}</Link></li>
                <li><Link to="/contact" id="contact" tabIndex={(props.showSidebar === "init" || props.showSidebar === "hide") ? -1 : 0}
                    onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}
                    onFocus={handleHover} onBlur={handleMouseLeave}>Contact</Link></li>
            </ul>
            <div className="lang">
                <p onClick={props.handleLang}>{props.i18n?.lang}</p>
            </div>

        </div>
    );

    function handleHover(e) {
        if (window.innerWidth > 700) {
            const id = e.target.id;
            if (splitTexts[id] === null) {
                const split = new SplitText(e.target, { type: "chars" });
                const tween = gsap.to(split.chars, { color: "#74d8ff", duration: .5, stagger: .025, paused: true });
                setSplitTexts(Object.assign(splitTexts, { [id]: tween }));
            }
            splitTexts[id].play();
        }
    }

    function handleMouseLeave(e) {
        if (window.innerWidth > 700) {
            splitTexts[e.target.id].reverse();
        }
    }

}