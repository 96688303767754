import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { CustomEase } from "gsap/CustomEase";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { SplitText } from "gsap/SplitText";

function initAnimation(seen, setSeen, showSidebar, setShowSidebar) {

    gsap.registerPlugin(SplitText, TextPlugin, CustomEase, MorphSVGPlugin, DrawSVGPlugin);
    CustomEase.create("return", "M0,0 C0,0 0.296,1 0.5,1 0.702,1 1,0 1,0")
    let select = e => document.querySelector(e);
    let playTween, pauseTween;

    let tl = gsap.timeline({
        onReverseComplete: function () {
            this.timeScale(1);
            this.play();
            checkControlBarStyles()
        },
        onUpdate: function () {
            select(".slider").value = this.progress();
            if (this.progress() < 1 && this.progress() > .05 && showSidebar !== "hide") {
                setShowSidebar("hide");
            }
        },
        onComplete: function () {
            this.pause();
            gsap.from(".pause-play, .slider", { autoAlpha: 0 }, "<")
            checkControlBarStyles();
            setSeen(true)
            setShowSidebar("show");
        },
    });
    const splits = createSplitTexts();
    MorphSVGPlugin.convertToPath(".init");
    select(".skip").addEventListener("click", () => {
        tl.timeScale(1);
        tl.progress(100);
    });
    select(".rewind").addEventListener("click", () => {
        tl.timeScale(20);
        tl.reverse();
    });
    initControlBar();

    tl.from(".skip", { autoAlpha: 0 })
        //BONJOUR
        .from(".bonjour", { scale: 0, ease: "back", autoAlpha: 0 }, "+=.25")
        .to(".bonjour", { autoAlpha: 0 }, "+=.5")

        //PRESENTATION
        .set(".pres", { visibility: "inherit" })
        .from(splits.pres.chars, { autoAlpha: 0, duration: 2, stagger: .02 })
        .to(".pres", { x: -2000, ease: "back.in(1)", duration: 1.5 }, "+=2")

        // J'UTILISE
        .set(".utilise", { visibility: "inherit" })
        .from(splits.utilise.chars, { x: 100, rotateY: 90, stagger: .075, autoAlpha: 0, transformOrigin: "50% 50%, -400" })
        .to(".utilise", { autoAlpha: 0 }, "+=.5")

        // HTML / CSS
        .set(".htmlcss", { visibility: "inherit" })
        .from(splits.htmlcss.words, {
            x: gsap.utils.wrap([-2000, 0, 2000]),
            scale: gsap.utils.wrap([1, 0, 1]), duration: 1, ease: "power2.out"
        })
        .to(splits.htmlcss.words, {
            y: gsap.utils.wrap([-2000, 0, 2000]),
            scale: gsap.utils.wrap([1, 0, 1]), duration: 1, ease: "power2.in"
        }, "+=.5")

        // CMS
        .set(".cms", { visibility: "inherit" })
        .from(splits.cms.chars, {
            y: gsap.utils.wrap([-100, 100]),
            stagger: {
                from: "center",
                each: .03
            },
            autoAlpha: 0
        })
        .to(splits.cms.words, {
            autoAlpha: 0, scale: 0, duration: 2, transformOrigin: "50% 50%, -400", ease: "power2.in",
            rotateY: gsap.utils.random(-360, 360, true), rotateX: gsap.utils.random(-360, 360, true)
        }, "+=.5")

        // PHP
        .set(".php", { visibility: "inherit" }, "-=1.2")
        .from(splits.php.words, {
            y: -800, autoAlpha: 0, duration: 1, ease: "bounce", stagger: {
                amount: 1,
                ease: "power2.out"
            }
        }, ">")
        .to(".symfony", { text: "Bon...", duration: .8, ease: "linear" }, "+=.25")
        .to(".symfony", { text: "Bon... Je peux apprendre Symfony s'il le faut", duration: 2, ease: "linear" })
        .to(splits.php.chars, {
            perspective: 600, rotateY: 360, autoAlpha: 0, duration: .5, stagger: {
                amount: 1,
                ease: "power1.out"
            }
        }, "+=.5")
        .to(".symfony", { scaleY: 0, autoAlpha: 0, duration: .8 }, "<.5")

        // JAVASCRIPT
        .from(".js", { x: -2000, autoAlpha: 0, ease: "back(1.2)", duration: .75 })
        .addLabel("javascript")

        //MORPHSVG
        .set(".logo", { autoAlpha: 1 }, "javascript")
        //ANGULAR
        .from(".draw", { drawSVG: "0%", duration: 1, stagger: .25 }, "javascript")
        .from(".draw", { fill: "rgba(0,0,0,0)" }, ">-.5")
        .set(".draw", { stroke: "none", strokeWidth: 0 }, ">.2")
        //REACT
        .to(".path1", { morphSVG: ".react1", fill: "#61DAFB" }, ">")
        .to(".path2", { morphSVG: ".react1", fill: "#61DAFB" }, "<")
        .to(".path3", { morphSVG: ".react2", fill: "#61DAFB" }, "<")
        // VUE
        .to(".path1", { morphSVG: ".vue1", fill: "#34495e" }, ">.5")
        .to(".path2", { morphSVG: ".vue1", fill: "#34495e" }, "<")
        .to(".path3", { morphSVG: ".vue2", fill: "#41b883" }, "<")
        //NODE
        .to(".path1", { morphSVG: ".node1", fill: "#689f63" }, ">.5")
        .to(".path2", { morphSVG: ".node1", fill: "#689f63" }, "<")
        .to(".path3", { morphSVG: ".node2", fill: "#689f63" }, "<")
        //END
        .to(".logo", { scale: .5, autoAlpha: 0 }, ">.5")

        .from(".js-content", { y: -150, autoAlpha: 0, scale: .5, duration: .65, stagger: 1 }, "javascript+=.75")
        .to(".js-content:not(.gsap)", { y: 150, autoAlpha: 0, scale: .5, duration: .65, stagger: 1 }, "<1")

        // GSAP
        .to(splits.gsap.chars, { x: gsap.utils.wrap([-20, -7, 7, 20]) })
        .to(splits.gsap.chars, { scale: 1.5, color: "#88CE02", ease: "return", duration: .5, stagger: .5 })

        //TRANSITION GRILLE
        .set(".veil *", { visibility: "inherit" })
        .from(".veil *", {
            scale: 0, duration: 1, stagger: {
                grid: "auto",
                each: .05,
                from: "edges"
            }
        })
        .set(".js, .gsap", { visibility: "hidden" }, "+=.25")
        .set(".contact", { visibility: "inherit" })
        .to(".veil *", {
            scale: 0, duration: 1, stagger: {
                grid: "auto",
                each: .05,
                from: "center"
            }
        })

        //END
        .to(".skip", { autoAlpha: 0 })
        .from(".rewind", { autoAlpha: 0, yPercent: 100 }, "<");

    if (seen) {
        tl.progress(1);
    }
    // GSDevTools.create({ animation: tl, css: "z-index: 2" });


    function createSplitTexts() {
        return ({
            pres: new SplitText(".pres"),
            utilise: new SplitText(".utilise", { type: "chars" }),
            htmlcss: new SplitText(".htmlcss", { type: "words" }),
            cms: new SplitText(".cms", { type: "words, chars" }),
            php: new SplitText(".php", { type: "words, chars" }),
            gsap: new SplitText(".gsap", { type: "chars" })
        });
    }

    function initControlBar() {
        playTween = gsap.to(".play-cont", { fill: "#88CE02", duration: .1 });
        pauseTween = gsap.to(".pause-cont", { fill: "#88CE02", paused: true, duration: .1 });

        select(".slider").addEventListener("input", (event) => {
            tl.pause();
            tl.timeScale(1);
            tl.progress(event.target.value);
            checkControlBarStyles();
        });
        select(".play").addEventListener("click", () => {
            tl.timeScale(1);
            tl.play();
            checkControlBarStyles();
        })
        select(".pause").addEventListener("click", () => {
            tl.timeScale(1);
            tl.pause();
            checkControlBarStyles();
        })
    }

    function checkControlBarStyles() {
        if (tl.paused() && pauseTween.progress() < 1) {
            pauseTween.play();
            playTween.reverse();
        } else if (!tl.paused() && playTween.progress() < 1) {
            playTween.play();
            pauseTween.reverse();
        }
    }
}

export default initAnimation;