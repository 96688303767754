export default function Creation(props){
    return(
        <div className="creation">
            <h2>{props.name}</h2>
            <div className="image-wrap">
                <a href={props.link} target="_blank"><img src={props.image} alt={props.name}/></a>
            </div>
            <div className="desc">
                {props.children}
            </div>
            <a href={props.link} target="_blank"><button>{props.i18n?.projects1}</button></a>
        </div>
    )
}