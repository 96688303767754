export default function TextInput(props){
    let input
    if(props.type==="textarea"){
        input = <textarea id={props.kurasu} onChange={props.handleChange} name={props.kurasu} value={props.content} cols="10" required/>;
    } else{
        input = <input type="text" id={props.kurasu} value={props.content} name={props.kurasu} onChange={props.handleChange} required/>;
    }

    return(
        <div className={props.kurasu}>
        <label htmlFor={props.kurasu}>{props.name}</label>
        {input}
        </div>
    );
}