import './App.css';
import Front from './components/front/front';
import { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Sidebar from './components/sidebar/sidebar';
import Presentation from './components/presentation/presentation';
import WebApps from './components/webapps/webapps';
import Contact from './components/contact/contact';
import gsap from 'gsap/gsap-core';
import { CustomEase } from "gsap/CustomEase";
import { en, fr } from './lang';

function App() {

  gsap.registerPlugin(CustomEase);
  CustomEase.create("customBounce", "M0,0,C0.132,0,0.73,0.994,0.734,0.998,0.745,0.994,0.746,0.966,0.872,0.966,0.98,0.966,1,1,1,1");
  const [start, setStart] = useState(true);
  const [showSidebar, setShowSidebar] = useState("init");
  const [panelPosition, setPanelPosition] = useState(false);
  const [memory, setMemory] = useState("");
  const location = useLocation();
  const [seen, setSeen] = useState(location.pathname.length < 5 ? false : true);
  const panel = useRef(null);
  const [currentLang, setCurrentLang] = useState(location.pathname.includes("/en") ? "en" : "fr");

  const i18n = currentLang === "fr" ? { ...fr } : { ...en };

  useEffect(() => {
    if (start) {
      if (location.pathname === "/") {
        gsap.set(panel.current, { xPercent: -100 });
        setShowSidebar("init");
      } else {
        setPanelPosition(true);
        setSeen(true);
        setShowSidebar("instant");
      }
      setStart(false);
    }

    switch (location.pathname) {
      case "/presentation":
        setMemory(<Presentation i18n={i18n} />);
        break;
      case "/appweb":
        setMemory(<WebApps i18n={i18n} />);
        break;
      case "/contact":
        setMemory(<Contact i18n={i18n} />);
        break;
      default:
    }

    if (panelPosition === false && location.pathname !== "/") {
      gsap.to(panel.current, { xPercent: 0, ease: "customBounce", duration: .85 });
      setPanelPosition(true);
    } else if (panelPosition === true && location.pathname === "/") {
      gsap.to(panel.current, { xPercent: -100, duration: .85, ease: "power1.in" });
      setPanelPosition(false);
    }

  }, [location]);

  return (
    <div className="wrap">
      <Front seen={seen} setSeen={setSeen} setShowSidebar={setShowSidebar} showSidebar={showSidebar} i18n={i18n} />
      <div className="content" ref={panel}>
        <Switch>
          <Route path="/presentation">
            <Presentation i18n={i18n} />
          </Route>
          <Route path="/appweb">
            <WebApps i18n={i18n} />
          </Route>
          <Route path="/contact">
            <Contact i18n={i18n} />
          </Route>
          <Route path="/" exact>
            {memory}
          </Route>
        </Switch>
      </div>
      <Sidebar showSidebar={showSidebar} i18n={i18n} handleLang={handleLang} />
    </div>
  );

  function handleLang(e) {
    if (currentLang === 'fr') {
      if (location.pathname[location.pathname.length - 1] === "/") {
        window.location.href = window.location.href + "en";
      }
      else {
        window.location.href = window.location.href + "/en";
      }
    }
    if (currentLang === 'en') {
      window.location.href = window.location.href.replace("/en", "");
    }
  }

}

export default App;
