import { useState } from "react";
import "./contact.css"
import TextInput from "./textInput";
import { useEffect } from "react";

export default function Contact(props) {
    const [nom, setNom] = useState("");
    const [mail, setMail] = useState("");
    const [message, setMessage] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [buttonValue, setButtonValue] = useState(props.i18n.validate);
    const [response, setResponse] = useState("");

    useEffect(() => document.title=`Léonard Reibel - ${props.i18n?.contact}`)

    return (
        <div className="inside-wrap">
            <div className="contact">
                <h1>{props.i18n?.contact}</h1>
                <p className="intro">{props.i18n?.contact1}</p>
                <form onSubmit={handleSubmit}>
                    <TextInput name={props.i18n?.contact2} kurasu="Nom" content={nom} handleChange={handleChange} />
                    <TextInput name={props.i18n?.contact3} kurasu="Mail" content={mail} handleChange={handleChange} />
                    <TextInput name={props.i18n?.contact4} kurasu="Message" type="textarea" content={message} handleChange={handleChange} />
                    <input type="submit" value={buttonValue} disabled={disabled} />
                </form>
                <div className="response">
                    {response === "ok" ? <p>{props.i18n?.contact5}</p> : ""}
                    {response === "error" ? <p>{props.i18n?.contact6}</p> : ""}
                </div>
                <p className="disclaimer">{props.i18n?.contact7}</p>
            </div>
        </div>
    );

    function handleChange(e) {
        switch (e.target.name) {
            case "Nom":
                setNom(e.target.value);
                break;
            case "Mail":
                setMail(e.target.value);
                break;
            case "Message":
                setMessage(e.target.value);
                break;
            default:
                throw new Error("Erreur du formulaire");
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setButtonValue(props.i18n?.wait);
        fetch("https://formspree.io/f/mdopqaor", {
            method: "POST", headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ nom: nom, mail: mail, message: message })
        })
            .then(response => response.json())
            .then(response => {
                setDisabled(false);
                setButtonValue(props.i18n?.validate);
                if (response.ok) {
                    setResponse("ok");
                    setNom("");
                    setMail("");
                    setMessage("");
                } else {
                    setResponse("error");
                }
            })
    }
}

