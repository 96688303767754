export const fr = {
    lang: "EN",
    intro1: "Bonjour !",
    intro2: "je suis Léonard Reibel, futur diplomé du master CAWEB (création de sites web multilingues, localisation et gestion de contenus), et actuellement apprenti au sein de la société YggVal en tant que développeur d'ERP ",
    intro3: "Mes compétences",
    intro4: "HTML5 / CSS3",
    intro5: "CMS: Drupal et Wordpress",
    intro6: "PHP avec Laravel",
    intro7: "Contactez-moi !",
    intro8: "Ou via mon",
    intro9: "formulaire de contact",
    intro10: "Passer",
    intro11: "Rembobiner !",

    home: "Accueil",
    about: "Présentation",
    projects: "Mes créations",
    title: "Léonard Reibel - Développeur Web",
    contact: "Contact",

    about1: " Je m’appelle Léonard Reibel, j'ai 26 ans et termine actuellement mes études en master CAWEB (Création de sites web multilingues). Je travaille en tant qu'apprenti pour la société YggVal comme développeur d'ERP jusqu'au 30 septembre 2022.",
    about2: " Je suis à l’aise avec la programmation backend comme frontend, et m’intéresse particulièrement à la création de SPAs à l’aide des Frameworks JavaScript Angular, React et Vue.  " ,
    about3: " J’ai également une expérience avancée avec le CMS Wordpress (Création de thème, templates, blocs personalisés, etc.).  " ,
    about4: " Au niveau du backend, j’utilise généralement les combinaison Node.js / NestJs, C#/ASP.NET ou PHP avec le Framework Laravel. Je sais aussi me servir de Google Firebase.  ",
    about5: " Pour ce qui est de l’animation, je maîtrise les méthodes CSS standards (transitions et keyframes), mais j’utilise généralement la bibliothèque JavaScript d’animation GSAP pour les travaux plus complexes.  ",
    about6: "Si mon profil vous intéresse, n’hésitez pas à ",
    about7: "me contacter",
    about8: "C'est moi !",

    projects1: "Voir",
    projects2: "Vous pouvez trouver sur cette page des exemples d'applications web que j'ai développées",
    projects3: "Site de l'association \"Les Amis des Marins\" de Port-de-Bouc.",
    projects4: "Réalisé dans le cadre de mon stage en première année de master, sous les directives de l'association et d'après des maquettes réalisées sur figma",
    projects5: "Développement via WordPress avec création de thème et de templates en PHP",
    projects6: "Reproduction de l'animation de lancement de la console GameCube en HTML, CSS et JavaScript",
    projects7: "Réalisé en tant que défi personnel",

    contact1: "Vous pouvez me contacter par mail (leonard.reibel@gmail.com), ou en utilisant le formulaire ci-dessous:",
    contact2: "Nom",
    contact3: "Mail",
    contact4: "Message",
    contact5: "Merci !",
    conatct6: "Une erreur s'est produite...",
    contact7: "Les données recueillis par ce formulaire me sont transmises immédiatement par email et ne sont ni conservées sur ce site, ni transférées à un quelconque organisme tiers",

    validate: "Valider",
    wait: "Patientez...",

    well1: "Bon...",
    well2: "Bon... Je peux apprendre Symfony s'il le faut"
}


export const en = {
    lang: "FR",
    intro1: "Hello !",
    intro2: "I am Léonard Reibel, future graduate of the master CAWEB (Web design, localization and content management), and currently an apprentice at the YggVal company as an ERP developer",
    intro3: "My skills",
    intro4: "HTML5 / CSS3",
    intro5: "CMS: Drupal and Wordpress",
    intro6: "PHP with Laravel",
    intro7: "Contact me !",
    intro8: "Or through my",
    intro9: "contact form",
    intro10: "Skip",
    intro11: "Rewind !",

    home: "Home",
    about: "About me",
    projects: "My projects",
    title: "Léonard Reibel - Web developper",
    contact: "Contact",

    about1: " My name is Leonard Reibel, I am 26 years old and I am currently finishing my studies in master CAWEB (Multilingual web design). I am working as an apprentice for the YggVal company as an ERP developer until September 30th 2022.",
    about2: " I am comfortable with both backend and frontend programming, and am particularly interested in creating SPAs using the Angular, React and Vue JavaScript Frameworks.  " ,
    about3: "  I am also well versed with the Wordpress CMS (theme creation, templates, custom blocks, etc.).  " ,
    about4: " For the backend, I usually use the combinations of Node.js / NestJs, C#/ASP.NET or PHP with the Laravel Framework. I can also use Google Firebase.  ",
    about5: " When it comes to animation, I am condident with the standard CSS methods (transitions and keyframes), but I usually use the GSAP JavaScript animation library for more complex work.  ",
    about6: "If you are interested in my profile, don't hesitate to",
    about7: "contact me",
    about8: "It's me !",

    projects1: "Discover",
    projects2: "You can find on this page some examples of web applications I have developed.",
    projects3: "Website for the association \"Les Amis des Marins\" of Port-de-Bouc, France.",
    projects4: "Realized within the context of my internship in my first year of master, under the directives of the association and according to models designed on Figma.",
    projects5: "Développement via WordPress avec création de thème et de templates en PHP.",
    projects6: "Reproduction of the GameCube console launch animation in HTML, CSS and JavaScript.",
    projects7: "Completed as a personal challenge.",

    contact1: "You can contact me by email (leonard.reibel@gmail.com), or by using the form below:",
    contact2: "Name",
    contact3: "Email",
    contact4: "Message",
    contact5: "Thank you !",
    conatct6: "An error occured...",
    contact7: "The data collected by this form are immediately transmitted to me by email and are neither kept on this website, nor transferred to any third party organization.",

    validate: "Valider",
    wait: "Patientez...",

    well1: "Well...",
    well2: "Well... I can learn Symfony if that's needed"
}