import "./presentation.css"
import { Link } from "react-router-dom";
import { useEffect } from "react";
const image = require("./photo.jpg");

export default function Presentation(props) {

    useEffect(() => document.title=`Léonard Reibel - ${props.i18n?.about}`)
    
    return (
        <div className="inside-wrap">
            <div className="presentation">
                <h1>{props.i18n?.about}</h1>
                <div className="pres-content">
                    <div className="text">
                        <p>{props.i18n?.about1}</p>
                        <p>{props.i18n?.about2}</p>
                        <p>{props.i18n?.about3}</p>
                        <p>{props.i18n?.about4}</p>
                        <p>{props.i18n?.about5}</p>
                        <p>
                           {props.i18n?.about6} <Link to="/contact">{props.i18n?.about7}</Link> !
                        </p>
                    </div>
                    <img src={image.default} alt={props.i18n?.about8} />
                </div>
            </div>
        </div>
    );
}
